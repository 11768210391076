import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Aside = makeShortcode("Aside");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
    </PageDescription>
    <h2>{`All inclusive web designing and development solutions.`}</h2>
    <Row mdxType="Row">
      <Column colMd={5} colLg={8} mdxType="Column">
Our web development solutions covers all your website needs from designing to a fully customised and functional website.
        <p>{`We focus on creating highly optimised, speedy, and responsive web pages so that your business rank well for a variety of search terms and attract a steady stream of new customers.`}</p>
        <p>{`All Magnus Code websites are developed with built in mobile integration to improve both the experience of visitors and SEO Rankings.`}</p>
      </Column>
      <Column colMd={2} colLg={3} offsetMd={1} offsetLg={1} mdxType="Column">
        <Aside aria-label="Example aside" mdxType="Aside">
          <p><strong parentName="p">{`Web application for any use case.`}</strong></p>
          <p>{`We build high performance and reliable enterprise web applications.`}</p>
        </Aside>
      </Column>
    </Row>
    <h3>{`Some of our key expertise includes:`}</h3>
    <Row className="resource-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
        <ResourceCard subTitle="React, Angular" title="Full stack design and development" aspectRatio="2:1" actionIcon="none" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "32px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMFAv/EABgBAAMBAQAAAAAAAAAAAAAAAAABAgMF/9oADAMBAAIQAxAAAAHUMzl66aJHciioh//EABoQAAMBAAMAAAAAAAAAAAAAAAECAxESFCD/2gAIAQEAAQUC8bRlieU2QOOumIgmP//EABURAQEAAAAAAAAAAAAAAAAAABAB/9oACAEDAQE/ASn/xAAZEQADAAMAAAAAAAAAAAAAAAAAAQIREjH/2gAIAQIBAT8BmdhrBLY+n//EAB8QAAEDAwUAAAAAAAAAAAAAAAEAAhEDEiEgMUFRcf/aAAgBAQAGPwLQ+C4xU43hCCT6rSrc9zOVa1f/xAAeEAACAgEFAQAAAAAAAAAAAAABEQAhMSBBUXGBkf/aAAgBAQABPyHQViCAg/hAnJaJG/Zc1HgqUQVGnJ3LOLN7z//aAAwDAQACAAMAAAAQJxi+/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAwEBPxBctIdg5f/EABgRAQEBAQEAAAAAAAAAAAAAAAEAEUFR/9oACAECAQE/EPVkiSAAPZqtv//EABoQAQADAQEBAAAAAAAAAAAAAAEAETFRECH/2gAIAQEAAT8Q8JrKppMFJl5942olzQdI1QoNshMRIXwzlJbrFRQVSrU6r2f/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/e94b60debaf82f1f20d1fb7680303ff9/3bf52/js.webp 32w"],
                "sizes": "(max-width: 32px) 100vw, 32px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/e94b60debaf82f1f20d1fb7680303ff9/7cb20/js.jpg 32w"],
                "sizes": "(max-width: 32px) 100vw, 32px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/e94b60debaf82f1f20d1fb7680303ff9/7cb20/js.jpg",
                "alt": "Javascript icon",
                "title": "Javascript icon",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>
        </ResourceCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
        <ResourceCard title="Cloud Infrastructure" subTitle="Microsoft Azure" aspectRatio="2:1" actionIcon="none" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "32px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAEDBAX/xAAXAQEBAQEAAAAAAAAAAAAAAAAABAID/9oADAMBAAIQAxAAAAHNZZrqj5aXTEAA/8QAGhABAAIDAQAAAAAAAAAAAAAAAgEDABASIP/aAAgBAQABBQLEEdUyShPdXj//xAAXEQADAQAAAAAAAAAAAAAAAAABAhEg/9oACAEDAQE/AQtx/8QAFxEAAwEAAAAAAAAAAAAAAAAAAQIRIP/aAAgBAgEBPwEtMf/EAB0QAAEDBQEAAAAAAAAAAAAAAAEAAhEDECAhMWH/2gAIAQEABj8CW7FzujgRZUgezj//xAAcEAEAAQQDAAAAAAAAAAAAAAABABARMXEgIWH/2gAIAQEAAT8hywUT5po+XRuYb1sljMSzbh//2gAMAwEAAgADAAAAEC8Ygv/EABYRAQEBAAAAAAAAAAAAAAAAAAEhIP/aAAgBAwEBPxAhW4//xAAXEQADAQAAAAAAAAAAAAAAAAAAASEQ/9oACAECAQE/EHNFCb//xAAeEAEBAAIBBQEAAAAAAAAAAAABEQAhQRAxUWGBof/aAAgBAQABPxAGBVWAc5IoLW+Cx9xyvnLIlaFwTalfsTdl738y2ooxjTHPnT//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/5b3274cfcb64b190b4816941f30d87de/3bf52/az.webp 32w"],
                "sizes": "(max-width: 32px) 100vw, 32px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/5b3274cfcb64b190b4816941f30d87de/7cb20/az.jpg 32w"],
                "sizes": "(max-width: 32px) 100vw, 32px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/5b3274cfcb64b190b4816941f30d87de/7cb20/az.jpg",
                "alt": "Azure icon",
                "title": "Azure icon",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>
        </ResourceCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
        <ResourceCard title="Authorization Server" subTitle="Single Sign On - MagnusId" aspectRatio="2:1" actionIcon="none" mdxType="ResourceCard">
          <img {...{
            "src": "/2a12a97ed97a60ae4ad0ff4bdfc5ba44/password.svg",
            "alt": "Password icon"
          }}></img>
        </ResourceCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
        <ResourceCard title="Custom Web Design" subTitle="UI/UX design" aspectRatio="2:1" actionIcon="none" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "32px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAETElEQVQ4y42TW2yUVRDHf7tLjD5ofDDGGEOwxQhtIYSoDZcYn3wkMTGQCIRCgFJ6U6oWRDEIQgoVEiIphD4IChRKL5arBOQOLaW0LFQQLAps2e5uy/ay7La7Z86Y72sflBDjJP98k5nzn+8/c+ZgD4Keh0H/TMzpdI+0ZGD8k5FrkzA3piGNIIFyJLAeafRgbkxH/JOGzzhnL4zxJFunoqfBHgOkFlI7vZjjr3jNpTeRlvFjTeuEGXI1Y4Fcy/7SND2/Ve4W1UtHfr1pemGr+LNXOjnTmjVDWsanmStjMSde9pr9XqQOsD+DNPh8shtkL1lyKT1m2t9WuZ6tpvV1lfZ31faeURs9pXJjmprWdJXr76h75mJav+xmnPzo1PD6nG6xDXi0FvTiiz6posVcTFO5OTVprqaJ+N8yEj1jbPSksdFfjURPGbk22Zir6SI3pyXNhTEqe2nSSJZXT4E9jAepwpeqBKlhm+xF5cqEpLmeodKWZaXnF2t7z1rpOejC9p6z0nPESmuGNf5MlebMpMupY0uqAmQPPvQEmGrmmP2o2ecz5vJoK21vqISr1UbPqkRqVLoPDSNSozZ6TiVUpdKWrqZptDVVPjH7HC6znItB28i0DfTZBtTWY8Q/UaX/uLWJW2oHzqqNNaqNXRpB43As8btK/1ErbZkux+Ue5JHeYRzxCtoTlehgJalEBSq38qw8+EHlj7UqHRtUOspGvv/wnVxgp8pvi63DGawk6dSIV9BGJJ/czhy0ayHaOccjsXKv2hqs2YWa3aj56Qk4sV2o1mAHNvi0c7ZHXG4OGs5lAfcWQvfHrOzKR0NLMOFCrNS7g7amBpUnYA6M5OrQUD42lIcJLkW7i/n8wRzg5BQ8lydDpIhtTtHgfFIDm1E9gjXVWLfICFy/GqtHsQPfYYMLSHUtRSNFbGmdAs0T8TC4Go/64SUgXMShYC4azifpKJBarKvoSXW1aGgpya7FaLiIuvXO5SZhaA0eHn8NvaV4E6tBy3guVEi7M4/YZpJ6BDX7XZUuHN+JDZSTfJiDhgpp0+U8M7gM+krwJlYBfcshUuzO0feoBOKrSA/m0u20Is4a1bnKHKXW9WtJdeWhwcWE42sYHS2GnkJ83bnQXwJES+BhLgQWQXAJo8IFEFvF9MA8hvo3onoU4y59NarHMH1laOdcEo/Xkd1dDKFFjArOh64c6P2CYTNl0PwefP8s/DUPXyAHuguZ7czTOO3Wuups6gA2uAjtXsbMwBK4Px/fiUy4/T44T+9fNvQN7jPUHXDnQ3x/fgCdH1Hau8698ZQeJhVd6+wqn3TMgttz8ekeUIX4Jp5usVKIl4JdC/e34Ql8BuECdiSr0eQ+NJTH1ocFcG8TXi2H5KeQKOW/7VEhxEogWoDnLjCokNhOY2I7541CM9C7DE98BfQW8P/M+WskD/qcdfoWhsp5LV7Gq7GNEF2BJ1wIia+eTv4btWxGO/XW8MsAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/481538f72a9432e1fd42fa2d4840a909/3bf52/sketch.webp 32w"],
                "sizes": "(max-width: 32px) 100vw, 32px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/481538f72a9432e1fd42fa2d4840a909/68686/sketch.png 32w"],
                "sizes": "(max-width: 32px) 100vw, 32px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/481538f72a9432e1fd42fa2d4840a909/68686/sketch.png",
                "alt": "Sketch icon",
                "title": "Sketch icon",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>
        </ResourceCard>
      </Column>
    </Row>
    <h2>{`Adaptable,Robust, Scalable`}</h2>
    <p>{`Building website through Magnus Code ensures your website works quickly, correctly and as expected.`}</p>
    <p>{`Built to web standards and tested regularly for issues with speed or functionality, every page is fast, functional and secure.`}</p>
    <p>{`We create future proof websites through our continuous development and maintenance solutions to keep up with evolution of our clients’ requirement.`}</p>
    <Row className="resource-card-group mt-4 " mdxType="Row">
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="Talk to our team" title="Contact Us" aspectRatio="2:1" actionIcon="arrowRight" href="/contact" color="light" className="blue-card" mdxType="ResourceCard"></ResourceCard>
  </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      